import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/uploadFile/icon-word.png'
import _imports_1 from '@/assets/uploadFile/to-right-small.png'
import _imports_2 from '@/assets/uploadFile/icon-pdf.png'


const _hoisted_1 = { class: "main-box" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["id", "accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "list-row",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadWord && _ctx.uploadWord(...args)))
      }, _cache[3] || (_cache[3] = [
        _createStaticVNode("<div class=\"left\" data-v-fdd49fe0><img alt=\"Vue logo\" src=\"" + _imports_0 + "\" data-v-fdd49fe0></div><div class=\"content\" data-v-fdd49fe0> 选择Word文档 </div><div class=\"right\" data-v-fdd49fe0><img alt=\"Vue logo\" src=\"" + _imports_1 + "\" data-v-fdd49fe0></div>", 3)
      ])),
      _createElementVNode("div", {
        class: "list-row",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadPdf && _ctx.uploadPdf(...args)))
      }, _cache[4] || (_cache[4] = [
        _createStaticVNode("<div class=\"left\" data-v-fdd49fe0><img alt=\"Vue logo\" src=\"" + _imports_2 + "\" data-v-fdd49fe0></div><div class=\"content\" data-v-fdd49fe0> 选择PDF文档 </div><div class=\"right\" data-v-fdd49fe0><img alt=\"Vue logo\" src=\"" + _imports_1 + "\" data-v-fdd49fe0></div>", 3)
      ]))
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "footer-message" }, " 其它文件类型正在适配中,敬请期待~ ", -1)),
    _createElementVNode("input", {
      style: {"display":"none"},
      id: _ctx.fileInputId,
      type: "file",
      single: "",
      accept: _ctx.fileAccept,
      multiple: "multiple",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onChangeHandler && _ctx.onChangeHandler(...args)))
    }, null, 40, _hoisted_3)
  ]))
}