<template >
    <div class="main-box">
        <div class="list">
            <div class="list-row" @click="uploadWord">
                <div class="left">
                    <img alt="Vue logo" src="@/assets/uploadFile/icon-word.png">
                </div>
                <div class="content">
                    选择Word文档
                </div>
                <div class="right">
                    <img alt="Vue logo" src="@/assets/uploadFile/to-right-small.png">
                </div>
            </div>

            <div class="list-row" @click="uploadPdf">
                <div class="left">
                    <img alt="Vue logo" src="@/assets/uploadFile/icon-pdf.png">
                </div>
                <div class="content">
                    选择PDF文档
                </div>
                <div class="right">
                    <img alt="Vue logo" src="@/assets/uploadFile/to-right-small.png">
                </div>
            </div>
        </div>
        <div class="footer-message">
            其它文件类型正在适配中,敬请期待~
        </div>


        <input
            style="display: none"
            :id="fileInputId"
            type="file"
            single
            :accept="fileAccept"
			multiple="multiple"
            @change="onChangeHandler"
        />


    </div>
</template>
  
<style scoped lang="scss" src='./uploadFile.scss' />
<script lang="ts" src='./uploadFile.ts' />
  
  
  